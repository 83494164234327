import React, { useState, useRef } from 'react';
import axios from 'axios';
import QRCode from 'qrcode.react';
import './styles.css';

const Register = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [qrCode, setQrCode] = useState('');
    const qrRef = useRef();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('http://localhost:5000/api/auth/register', {
                username,
                password,
                email
            });
            setQrCode(response.data.qrCode);
        } catch (err) {
            console.error(err);
            alert('Error registering user');
        }
    };

    const handlePrint = () => {
        const printContents = qrRef.current.innerHTML;
        const originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
    };

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} placeholder="Username" required />
                <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" required />
                <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" required />
                <button type="submit">Register</button>
            </form>
            {qrCode && (
                <div className="qr-container print-area" ref={qrRef}>
                    <h3>Scan this QR code to log in</h3>
                    <QRCode value={qrCode} />
                    <button onClick={handlePrint}>Print QR Code</button>
                </div>
            )}
        </div>
    );
};

export default Register;
