import React, { useState } from 'react';
import axios from 'axios';
import { BrowserQRCodeReader } from '@zxing/library';

const Login = () => {
    const [qrCode, setQrCode] = useState('');

    const handleScan = async () => {
        const codeReader = new BrowserQRCodeReader();
        try {
            const result = await codeReader.decodeOnceFromVideoDevice(undefined, 'video');
            setQrCode(result.text);
            const response = await axios.post('http://localhost:5000/api/auth/login', { qrCode: result.text });
            alert('Login successful');
            localStorage.setItem('token', response.data.token);
        } catch (err) {
            console.error(err);
            alert('Error logging in');
        }
    };

    return (
        <div>
            <button onClick={handleScan}>Scan QR Code</button>
            <video id="video" width="300" height="200"></video>
        </div>
    );
};

export default Login;
